import React from "react";
import "./index.css";


function App() {
  return (
    <div className="container">
      <div className="text-content">
        <h1 className="title text-[#FF3D00]">Hello 👋</h1>

        <p className="description">
          Jelly has been acquired! On behalf of the Jelly team, we want to thank
          each and every creator and partner who has supported us along our
          incredible journey.
        </p>

        <p className="description">
          You probably came here for a reason, so we have attached some links
          that may help you out below:
        </p>

        <ul className="list">
          <li>
            <a
              href="https://discord.gg/tiktokcreators"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              Creator/Brand Discord
            </a>
          </li>
        </ul>

        <p className="description">
          If you have any questions or need any support, please email: team@getjelly.app
        </p>

        <p className="description">With Love ❤️</p>

        <p className="description bold">-The Jelly Team</p>
      </div>
    </div>
  );
}

export default App;
